@tailwind base;
@tailwind components;
@tailwind utilities;

// Nextのコンポーネント化できないタグ用の共通スタイルを定義
@layer components {
    .container-main {
        @apply w-auto xl:w-[1200px] mx-4 md:mx-10 xl:mx-auto;
    }
    .indentation {
        @apply ml-0 sm:ml-4 md:ml-10 xl:ml-32;
    }
    .common-table{
        @apply [&_tr]:border-txt02 [&_tr]:border-solid [&_tr]:border-b
            [&_th]:block [&_th]:lg:table-cell [&_th]:pt-6 [&_th]:px-2 [&_th]:pb-1 [&_th]:lg:p-6 [&_th]:text-left [&_th]:text-base [&_th]:font-semibold
            [&_td]:block [&_td]:lg:table-cell [&_td]:pt-1 [&_td]:px-2 [&_td]:pb-4 [&_td]:lg:p-6 [&_td]:text-left [&_td]:text-sm [&_td]:sm:text-base
    }
    .input-text{
        @apply w-full h-16 px-4 py-0 bg-form leading-[64px] border-[1px] border-solid border-txt02 rounded-[6px]
            outline-0 [transition:color_0.3s_ease-in-out,_background-color_0.3s_ease-in-out,_border-color_0.3s_ease-in-out,_box-shadow_0.3s_ease-in-out]
            focus:shadow-[0_0_8px_0_rgba(145,129,204,0.2)] hover:border-primary;
    }
    .input-textarea{
        @apply w-full p-4 bg-form text-txt05 border-[1px] border-solid border-txt02 rounded-[6px]
            outline-0 [transition:color_0.3s_ease-in-out,_background-color_0.3s_ease-in-out,_border-color_0.3s_ease-in-out,_box-shadow_0.3s_ease-in-out]
            focus:shadow-[0_0_8px_0_rgba(145,129,204,0.2)] hover:border-primary;
    }
    .input-checkbox{
        @apply appearance-none h-[25px] w-[25px] border-[1px] border-solid border-txt02 rounded outline-0 duration-300
            cursor-pointer bg-form
            checked:border-primary checked:border-solid checked:bg-secondary_pink;
    }
    .input-select{
        @apply appearance-none block w-full max-w-full lg:w-[50%] lg:max-w-[50%] text-base font-normal text-txt05 leading-[1.3]
            pt-[9.6px] pr-[22.4px] pb-2 pl-[12.8px] box-border m-0 border-[1px] border-solid border-txt02
            shadow-[0_1px_0_1px_rgba(0,0,0,0.04)] rounded-lg bg-form
            bg-select-box bg-no-repeat bg-select-mark bg-[0.65em_auto,100%] outline-0
            [transition:color_0.3s_ease-in-out,_background-color_0.3s_ease-in-out,_border-color_0.3s_ease-in-out,_box-shadow_0.3s_ease-in-out]
            hover:border-primary focus:border-primary focus:shadow-[0_0_8px_0_rgba(145,129,204,0.2)]
            [&_option]:font-normal;
    }

    .button-default{
        @apply [transition:color_0.3s_ease-in-out,_background-color_0.3s_ease-in-out]
            py-0 px-3 align-middle font-semibold break-keep
            hover:cursor-pointer
            disabled:bg-txt03 disabled:border-none disabled:text-txt01 disabled:cursor-default
    }

    // ボタンサイズ（ボタンベース読み込み済）
    .button-s{
        @apply button-default
            h-8 leading-[32px] min-w-[40px] rounded-[16px] text-xs sm:text-sm;
    }
    .button-r{
        @apply button-default
            h-10 leading-[40px] min-w-[64px] rounded-[20px] text-sm sm:text-base;
    }
    .button-l{
        @apply button-default
            h-16 leading-[64px] min-w-[200px] rounded-[32px] text-sm sm:text-base py-0 px-6;
    }
    // ボタンの色指定
    .button-primary{
        @apply bg-primary text-txt01 hover:bg-primary-dark;
    }
    .button-gradation{
        @apply border-white text-txt01 bg-gradient-button
            hover:from-secondary_blue hover:via-primary hover:to-secondary_pink
            disabled:bg-none ;
    }
    .list-mark-icon{
        @apply ml-5
        before:inline-block before:align-middle before:bg-list-mark-image
        before:bg-no-repeat before:bg-contain before:h-3.5 before:w-3.5 before:ml-[-20px] before:pr-1.5;
    }
    .list-mark-vertical-icon{
        @apply ml-5
        before:inline-block before:align-middle before:bg-list-mark-image
        before:bg-no-repeat before:bg-contain before:h-3.5 before:w-3.5 before:ml-[-7px] before:pr-1.5;
    }
}

// ユーティリティ用の共通スタイルを定義
@layer utilities {

}

// スクリーンサイズごとに共通スタイルを定義
@layer screens {

}